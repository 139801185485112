.react-portal-hint__body {
  color: #ffffff;
  background-color: #383838;
  font-size: 0.8rem;
  line-height: 0.8rem;
  padding: 0.3rem;
  border-radius: 0.3rem;
  transition: opacity 0.5s ease-out;
  z-index: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); }
  .react-portal-hint__body:before {
    content: "";
    height: 4px;
    width: 4px;
    position: absolute;
    z-index: -1; }
  .react-portal-hint__body:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    z-index: 1; }
  .react-portal-hint__body.shown {
    opacity: 1; }
  .react-portal-hint__body.hidden {
    opacity: 0; }
  .react-portal-hint__body.top {
    transform: translateY(-4px); }
    .react-portal-hint__body.top:before {
      transform: translate(-100%) rotate(45deg);
      transform-origin: right bottom;
      box-shadow: 1.4142px 1.4142px 4px rgba(0, 0, 0, 0.3);
      height: 6px;
      width: 6px;
      bottom: -4px;
      left: 50%; }
    .react-portal-hint__body.top:after {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid #383838;
      left: 50%;
      bottom: -4px;
      transform: translateX(-50%); }
  .react-portal-hint__body.bottom {
    transform: translateY(4px); }
    .react-portal-hint__body.bottom:after {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 4px solid #383838;
      left: 50%;
      top: -4px;
      transform: translateX(-50%); }
  .react-portal-hint__body.left {
    transform: translateX(-4px); }
    .react-portal-hint__body.left:before {
      transform: translate(-100%) rotate(45deg);
      transform-origin: right bottom;
      box-shadow: 1.4142px 1.4142px 4px rgba(0, 0, 0, 0.3);
      height: 4px;
      width: 4px;
      right: -4px;
      top: 50%; }
    .react-portal-hint__body.left:after {
      border-bottom: 4px solid transparent;
      border-top: 4px solid transparent;
      border-left: 4px solid #383838;
      top: 50%;
      right: -4px;
      transform: translateY(-50%); }
  .react-portal-hint__body.right {
    transform: translateX(4px); }
    .react-portal-hint__body.right:before {
      transform: translate(-100%) rotate(45deg);
      transform-origin: right bottom;
      box-shadow: 1.4142px 1.4142px 4px rgba(0, 0, 0, 0.3);
      height: 4px;
      width: 4px;
      left: 0;
      top: 50%; }
    .react-portal-hint__body.right:after {
      border-bottom: 4px solid transparent;
      border-top: 4px solid transparent;
      border-right: 4px solid #383838;
      top: 50%;
      left: -4px;
      transform: translateY(-50%); }
